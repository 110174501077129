<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Налаштування</v-subheader>
              <v-row class="dense-wrapper">
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_owner"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.show_owner ? 'Виводити власника' : 'Не виводити власника'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_encrypted_owner"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.show_encrypted_owner ? 'Приховати прізвище власника' : 'Не приховувати прізвище власника'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_square"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2"
                      :label="filters.show_square ? 'Виводити площу' : 'Не виводити площу'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.order_for_slice"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2"
                      :label="filters.order_for_slice ? 'Сортування для нарізки (увімкнено)' : 'Сортування для нарізки (вимкнено)'"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field filled
                                label="Додатковий текст"
                                hide-details
                                color="grey"
                                v-model="filters.additional_text"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Відбір</v-subheader>
              <v-row class="dense-wrapper">
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect v-model="filters.city"
                                        label="Населений пункт" filled
                                        select_type="city"
                  />
                </v-col>
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                        label="Вулиця(-ки)" multiple filled
                                        select_type="street" use_parent_id
                                        :disabled="!filters.city"
                  />
                </v-col>
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                        label="Будинок(-ки)" multiple filled
                                        select_type="building" use_parent_id
                                        :disabled="!filters.streets.length"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2 dense-wrapper">
                <v-col cols="12" md="12">
                  <v-switch
                      v-model="filters.use_end_balance"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2"
                      :label="filters.use_end_balance ? 'Відбір по кін. сальдо (включений)' : 'Відбір по кін.сальдо (виключений)'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-decimal filled :disabled="!filters.use_end_balance" label="Сальдо кін. від" hide-details v-model.number="filters.end_balance_start"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-decimal filled :disabled="!filters.use_end_balance" label="Сальдо кін. до" hide-details v-model.number="filters.end_balance_end"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select multiple filled :items="services" hide-details v-model="filters.only_services" color="grey" label="Абоненти із цими послугами"/>
                </v-col>
                <v-col cols="12">
                  <v-select multiple filled :items="services" hide-details v-model="filters.exclude_services" color="grey" label="Абоненти без цих послуг"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="filters.postal_index" hide-details filled color="grey" clearable
                                label="Поштовий індекс" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print">
      <div class="page page-a4 page-separator page-for-print" v-for="(page, index) in pages" :key="`page-${index}`"
           style="margin-bottom: 2px;">
        <div class="report-section" style="flex-wrap: wrap">
          <div class="rp-col-6 receipt" v-for="(col, idx) in items.filter(item => item.page === page)"
               :key="`col-idx-${idx}`">
            <div class="receipt-content" style="font-size: 11px">
              <div class="receipt-row" style="font-weight: 600">
                <div style="flex: 0 0 190px">
                  {{ print_data.organization_short_name }}
                </div>
                <div style="flex: 1; text-align: right; justify-content: flex-end">
                  Квитанція за {{ date_start | formatDate('MMMM yyyy') }}
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 0 0 238px; font-weight: bold">
                  {{ col.flat_owner }}
                </div>
                <div style="flex: 1; font-style: oblique; text-align: right; justify-content: flex-end">
                  Особ. рах. <span style="font-weight: bold; padding-right: 2px">{{ col.personal_id }}</span>
                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 0 0 250px; font-weight: bold">
                  {{ col.address }}
                </div>
                <div style="flex: 1; font-style: oblique; text-align: right; justify-content: flex-end" v-if="col.square !== 'не виводити'">
                  Заг. площа <span style="font-weight: bold; padding-right: 2px">{{ col.square | formatToFixed }}</span>
                </div>
              </div>
              <div class="empty-row" style="height: 1px"></div>
              <div class="receipt-row" style="font-size: 12px;">
                <div style="flex: 0 0 250px;" class="verdana-left">
                  1. Сальдо станом на {{ date_start | formatDate }}
                </div>
                <div style="flex: 1; text-align: right; justify-content: flex-end" class="verdana-right">
                  {{ (col.start_balance || 0) | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row">
                <div class="report-table">
                  <div class="report-table-row">
                    <div class="report-table-col" style="flex: 0 0 140px">
                      <span>Послуга</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 70px">
                      <span>Об’єм</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 65px">
                      <span>Тариф</span>
                    </div>
                    <div class="report-table-col" style="flex: 1;">
                      <span style="justify-content: flex-end">Нараховано</span>
                    </div>
                  </div>
                  <div class="report-table-row"
                       v-for="service in 6" :key="`col-idx-service-${service}`"
                  >
                    <div class="report-table-col" style="flex: 0 0 140px">
                      <span class="text-no-wrap">
                        {{ col.items[service - 1] ? col.items[service - 1].service_name : '' }}
                      </span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 70px">
                      <span>
                        {{ col.items[service - 1] ? col.items[service - 1].volume : '' }}
                      </span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 65px">
                      <span>
                        {{ col.items[service - 1] ? col.items[service - 1].tariff : '' }}
                      </span>
                    </div>
                    <div class="report-table-col" style="flex: 1">
                      <span style="justify-content: flex-end">
                        {{ col.items[service - 1] ? col.items[service - 1].charge : '' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty-row" style="height: 1px"></div>
              <div class="receipt-row" style="font-size: 12px; line-height: 14px;">
                <div style="flex: 0 0 250px" class="verdana-left">
                  2. Нараховано всього
                </div>
                <div class="verdana-right">
                  {{ col.charge | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row" style="font-size: 12px; line-height: 14px;">
                <div style="flex: 0 0 250px" class="verdana-left">
                  3. Перерахунок
                </div>
                <div class="verdana-right">
                  {{ col.recalculation | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row" style="font-size: 12px; line-height: 14px;">
                <div style="flex: 0 0 250px" class="verdana-left">
                  4. Монетизація/Пільга
                </div>
                <div class="verdana-right">
                  {{ col.monetary | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row" style="font-size: 12px; line-height: 14px;">
                <div style="flex: 0 0 250px" class="verdana-left">
                  5. Оплачено
                </div>
                <div class="verdana-right">
                  {{ col.pay | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row" style="font-size: 12px; line-height: 14px;">
                <div style="flex: 0 0 250px" class="verdana-left">
                  6. До оплати (сальдо на {{ date_end | formatDate }})
                </div>
                <div class="verdana-right">
                  {{ col.end_balance | formatToFixed }} грн.
                </div>
              </div>
              <div class="receipt-row"
                   style="padding: 3px 0 3px 3px; text-align: center; height: 74px;
                   width: 365px; overflow: hidden; line-height: 13px;">
                <div style="flex: 1; padding-top: 4px">
                  {{ col.additional_text }}
                </div>
                <div style="flex: 0 0 74px; border: 1px dashed grey; position: relative;">
                  <img v-if="col.qr_url"
                       :src="`${BASE_URL_AXIOS}/${col.qr_url}`"
                       alt="qr code"
                       style="height: 64px; width: 64px; object-fit: fill; position: absolute; left: 5px; top: 2px">
                  <template v-else>
                    <img v-if="organization.qr_url"
                         :src="`${BASE_URL_AXIOS}/${organization.qr_url}`"
                         alt="qr code"
                         style="height: 64px; width: 64px; object-fit: fill; position: absolute; left: 5px; top: 2px">
                  </template>
                </div>
              </div>
              <div class="receipt-row" style="padding-top: 4px">
                <div style="flex: 0 0 200px">
                  {{`Кабінет ${getBasePath()}`}}
                </div>
                <div style="flex: 1; text-align: right; justify-content: flex-end; align-items: flex-start; font-weight: 500; font-size: 0.72rem">
                  {{`Код: ${col.person_hash}`}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {BASE_URL_AXIOS} from "@/utils/axios";

export default {
  name: "receipt_simply",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'clear',
          'generate_xlsx', 'flat', 'generate_email', 'email_address', 'generate_send_by_one_email'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll'
        }
    ),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    without_flat() {
      return !this.flat || Object.keys(this.flat || {}).length === 0
    }
  },
  data() {
    return {
      pages: 0,
      per_page: 0,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      generate_send_by_one_email_watcher: null,
      clear_watcher: null,
      filters: {
        customTitle: false,
        title: 'Повідомлення про нарахування за {МісяцьЗвіту}',
        show_owner: true,
        show_square: true,
        show_encrypted_owner: false,
        city: null,
        streets: [],
        buildings: [],
        only_services: [],
        exclude_services: [],
        use_end_balance: false,
        end_balance_start: 0,
        end_balance_end: 0,
        additional_text: '',
        order_for_slice: false,
        postal_index: null
      },
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
      publicPath: process.env.BASE_URL,
      BASE_URL_AXIOS
    }
  },
  methods: {
    getBasePath() {
      return window.location.origin
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_square: this.filters.show_square,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            default_title: this.filters.customTitle,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            order_for_slice: this.filters.order_for_slice,
            postal_index: this.filters.postal_index
          }
        }
        payload.filters.user_url = this.getBasePath()
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }

        reportAPI.receipt_simply_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `receipt_simply_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              this.$emit('xlsx_loading_status', false)
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_square: this.filters.show_square,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            default_title: this.filters.customTitle,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            order_for_slice: this.filters.order_for_slice,
            postal_index: this.filters.postal_index
          }
        }
        payload.filters.user_url = this.getBasePath()
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }

        reportAPI.receipt_simply(payload)
            .then(response => response.data)
            .then(data => {
              this.setSplitter(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.expanded = []
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_square: this.filters.show_square,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            default_title: this.filters.customTitle,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            order_for_slice: this.filters.order_for_slice,
            postal_index: this.filters.postal_index
          },
          email: this.email_address
        }
        payload.filters.user_url = this.getBasePath()
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }
        reportAPI.receipt_simply_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_send_email_by_one_by_flat() {
      if (this.date_start && this.date_end) {
        this.$emit('email_by_one_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_square: this.filters.show_square,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            default_title: this.filters.customTitle,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            order_for_slice: this.filters.order_for_slice,
            postal_index: this.filters.postal_index
          }
        }
        payload.filters.user_url = this.getBasePath()
        reportAPI.receipt_simply_email_by_one(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_by_one_loading_status', false)
              if (data.error_text) {
                this.$store.dispatch(ALERT_SHOW, {color: 'error', text: data.error_text})
              } else {
                this.$store.dispatch(ALERT_SHOW,
                    {
                      color: 'success',
                      text: `Надсилання рахунків завершено. Успішно: ${data.success}. Помилково: ${data.error}`
                    }
                )
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_by_one_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_send_by_one_email')
            })
      }
    },
    setSplitter(payload) {
      let pages = 0
      let per_page = 6
      let items = payload.length

      payload.forEach((item, idx) => {
        if ((idx + 1) % per_page === 0) {
          item['page'] = pages + 1
          pages += 1
          items -= per_page
        } else {
          item['page'] = pages + 1
        }
      })

      if (items > 0) {
        pages += 1
        //   Array(items).fill(0).forEach((item, idx) => {
        //     payload[idx]['page'] = pages
        //   })
      }

      this.pages = pages
      this.per_page = per_page
      this.items = Object.freeze(payload)
    },
    formatDate,
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
    watch_generate_send_by_one_email() {
      this.generate_send_by_one_email_watcher = this.$watch(
          'generate_send_by_one_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_send_email_by_one_by_flat()
              }
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_clear() {
      this.clear_watcher = this.$watch(
          'clear',
          {
            handler(payload) {
              if (payload) {
                this.items = []
                this.pages = 0
                this.per_page = 0
                this.$emit('afterClear')
              }
            }
          }
      )
    },
    getContacts() {
      let contacts = ""
      if (this.print_data || this.print_data.contacts) {
        this.print_data.contacts.forEach(item => {
          contacts += `${item.name} ${item.value}  `
        })
      }
      return contacts
    }
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_clear()
    this.watch_generate_xlsx()
    this.watch_generate_email()
    this.watch_generate_send_by_one_email()
    if (this.without_flat) {
      reportAPI.report_settings("receipt_simply")
          .then(response => response.data)
          .then(data => {
            const server_filters = JSON.parse(data)
            Object.keys(this.filters).forEach(key => {
              if (server_filters[key] !== undefined) {
                this.filters[key] = server_filters[key]
              }
            })
            if (server_filters['grouping'] && this.grouping) {
              this.grouping.forEach(g => {
                g.on = !!server_filters.grouping.includes(g.value);
              })
            }
          })
    }
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.clear_watcher) {
      this.clear_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #cdcdcd;
$separator-border-color: #6b6b6b;

@media print {
  .page {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .report-table-group-header {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .report-table-group-content {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  //@page {
  //  size: A4 portrait;
  //}

  .page-separator {
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }
  //.separator {
  //  page-break-before: right;
  //  //break-before: page;
  //}
}

.page {
  .receipt {
    &:nth-child(1) {
      border-right: 1px dotted $separator-border-color;
      border-bottom: 1px dotted $separator-border-color;
      .receipt-content {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
    }

    &:nth-child(2) {
      border-bottom: 1px dotted $separator-border-color;
      .receipt-content {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }

    &:nth-child(3) {
      border-right: 1px dotted $separator-border-color;
      border-bottom: 1px dotted $separator-border-color;
      .receipt-content {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
    }

    &:nth-child(4) {
      border-bottom: 1px dotted $separator-border-color;
      .receipt-content {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }

    &:nth-child(5) {
      border-right: 1px dotted $separator-border-color;
      .receipt-content {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
    }

    &:nth-child(6) {
      .receipt-content {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }
  }
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.receipt {
  height: 97mm;
  font-family: Arial,serif;

  //&:nth-child(even) {
  //  border-left: 1px dotted grey;
  //  border-bottom: 1px dotted grey;
  //}

  .receipt-row {
    display: flex;

    .verdana-left {
      font-family: Verdana, Arial, serif;
      font-size: 12px;
      line-height: 14px;
    }
    .verdana-right {
      font-family: Verdana, Arial, serif;
      font-style: oblique;
      flex: 1; text-align: right; justify-content: flex-end;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.receipt-content {
  overflow: hidden;
  padding: 10px;
}

.report-table {
  width: 100%;
  text-align: center;

  .report-table-row:nth-child(1) {
    & > div {
      background-color: rgb(249 249 249 / 74%) !important;
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      font-weight: 500;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row:last-child {
    & > div {
      border-bottom: 1px solid $border-color;

      //&:nth-child(1) {
      //  border-left: 1px solid grey;
      //}
    }
  }

  .report-table-row {
    min-height: 16px;
    line-height: .65;
    font-size: 11px;

    & > div {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

}

.report-table-col {
  &:nth-child(1) {
    span {
      line-height: 0;
      justify-content: flex-start;
      white-space: nowrap;
      text-align: left;
      width: 140px;
      overflow: hidden;
    }
  }

  span {
    padding: 0 3px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.report-table-group {
  font-size: 12px;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $border-color !important;
  }

  .report-table-group-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;
    border-top: 1px solid $border-color;
    font-weight: 500;
    background-color: #f2f2f2;

    .report-table-col {
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }

  }

  .report-table-group-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;

    .report-table-col {
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      &:nth-child(1) {
        padding-left: 30px !important;
        padding-right: 5px;
        border-left: 1px solid $border-color;
      }
    }
  }
}

.col-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .col-group-row {
    display: flex;

    &:nth-child(1) {
      border-bottom: 1px solid $border-color;
      text-align: center;
      justify-content: center;
    }

    .report-table-col {
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }
  }
}

</style>